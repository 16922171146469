import React, { Component } from 'react';
import {
  ClearRefinements,
  Panel,
  RefinementList,
} from 'react-instantsearch-dom';
import './Facets.scss';

export default class Facets extends Component {
  render() {
    return (
      <aside className="filters">
        <ClearRefinements
          translations={{
            reset: 'Clear all filters',
          }}
        />

        <Panel header={<h5>Contract Category</h5>}>
          <RefinementList
            attribute="contractCategory"
            limit={10}
            searchable
            showMore
          />
        </Panel>

        <Panel header={<h5>Buyer Name</h5>}>
          <RefinementList attribute="buyerName" limit={5} searchable showMore />
        </Panel>

        <Panel header={<h5>Supplier Class</h5>}>
          <RefinementList
            attribute="supplierClass"
            limit={5}
            searchable
            showMore
          />
        </Panel>
      </aside>
    );
  }
}
