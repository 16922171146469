import React, { Component } from 'react';
import './App.scss';
import Header from './Header/Header';
import { Route, Switch as RouteSwitch } from 'react-router-dom';
import Supplier from './Supplier/Supplier';
import Search from './Search/Search';
class App extends Component {
  render() {
    return (
      <div>
        <Header />

        <div className="container">
          <RouteSwitch>
            <Route exact path={'/'} component={Search} />
            <Route path={'/contract/:id'} component={Supplier} />
          </RouteSwitch>
        </div>
      </div>
    );
  }
}

export default App;
