import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Supplier.scss';
import { Link } from 'react-router-dom';
import ContactInformation from '../ContactInformation/ContactInformation';
import SupplierUtility from './services/utility.service';
import SupplierHttp from './services/http.service';
import { Card, CardContent } from '@material-ui/core';
import ScanLink from '../ScanLink/ScanLink';
import Moment from 'moment';
export default class Supplier extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contactInfo: null,
      errorMessage: null,
      services: null,
      supplier: null,
    };

    this.getSupplierById(props.match.params.id);
  }

  getSupplierById(id) {
    SupplierHttp.getSupplierById(id)
      .then(supplier => {
        this.setState(() => ({
          contactInfo: SupplierUtility.getContactInfo(supplier),
          errorMessage: null,
          supplier,
        }));
      })
      .catch(errorMessage => {
        this.setState(() => ({
          contactInfo: null,
          errorMessage,
          supplier: null,
        }));
      });
  }

  render() {
    const { contactInfo, errorMessage, supplier } = this.state;
    const dateIngestFormat = 'YYYY-MM-DD h:m a';

    if (errorMessage) {
      return (
        <article>
          <h1>An Error Occurred.</h1>

          <p>{errorMessage}</p>
        </article>
      );
    }

    if (supplier === null) {
      return <span>Loading&hellip;</span>;
    }

    return (
      <div>
        <article className="supplier-container">
          <Card className="supplier">
            <CardContent>
              <h1>{supplier.supplierName}</h1>
              <h2 className="sub-header">{supplier.contractNumber}</h2>

              <div>
                <h3>Contract Details</h3>
                <ul>
                  <li>Vendor: {supplier.supplierName}</li>
                  <li>
                    Cooperative Contract #: {supplier.cooperativeContractNumber}
                  </li>
                  <li>Commodity: {supplier.contractDescription}</li>

                  <li>
                    Effective Dates:{' '}
                    {new Moment(
                      supplier.startDate,
                      dateIngestFormat,
                      true
                    ).format('M/D/YY')}{' '}
                    through{' '}
                    {new Moment(
                      supplier.endDate,
                      dateIngestFormat,
                      true
                    ).format('M/D/YY')}
                  </li>
                  <li>Payment Terms: {supplier.paymentTerms}</li>
                  <li>
                    Remaining Renewals:
                    <span>
                      {' '}
                      {supplier.renewalsRemaining} &times;{' '}
                      {supplier.renewalTerm} {supplier.renewalTermType}
                    </span>
                    <sup>
                      <a href="#notes1">1</a>
                    </sup>
                  </li>
                  <li>
                    Contract Summary:
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ` ${supplier.contractSummary}`,
                      }}
                    />
                    <sup>
                      <a href="#notes2">2</a>
                    </sup>
                  </li>
                  <li>
                    Contract Document: <ScanLink hit={supplier} />
                  </li>
                </ul>

                <h3>Vendor Details</h3>

                <ul>
                  <li>Vendor: {supplier.supplierName}</li>
                  <li>Business Classification: {supplier.supplierClass}</li>
                  <li>
                    Vendor Address:
                    <div className="margin-left-20">
                      <ContactInformation contactInfo={contactInfo} />
                    </div>
                  </li>
                  <li>
                    Vendor Contact:
                    <div className="margin-left-20">
                      {supplier.supplierSalesContactName}
                      <br />
                      <a href={`tel:${supplier.supplierSalesContactPhone}`}>
                        {supplier.supplierSalesContactPhone}
                      </a>
                      <br />
                      <a href={`mailto:${supplier.supplierSalesContactEmail}`}>
                        {supplier.supplierSalesContactEmail}
                      </a>
                      <br />
                      <a href={supplier.supplierURL}>{supplier.supplierURL}</a>
                    </div>
                  </li>
                </ul>

                <h3>Virginia Tech Buyer</h3>
                <ul className="no-bullets">
                  <li>
                    {supplier.buyerName}
                    <br />
                    {supplier.buyerContact}
                  </li>
                </ul>
              </div>

              <h3>Notes</h3>
              <ol>
                <li id="notes1">
                  Renewal Terms: The first number is the number of renewals, the
                  second is the renewal period increment.
                </li>
                <li>
                  Contract summary reflects active/executed contract, contract
                  renewals will be available after the contract start date.
                </li>
              </ol>
            </CardContent>
          </Card>
        </article>

        <p>
          <Link
            to={{
              pathname: `/`,
            }}
          >
            Home
          </Link>
        </p>
      </div>
    );
  }
}

Supplier.propTypes = {
  match: PropTypes.object.isRequired,
};
