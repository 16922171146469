import React from 'react';
import PropTypes from 'prop-types';
import {
  connectStateResults,
  Highlight,
  Hits,
  HitsPerPage,
  InstantSearch,
  Pagination,
  ScrollTo,
  SearchBox,
} from 'react-instantsearch-dom';
import { Link } from 'react-router-dom';
import Facets from '../Facets/Facets';
import ScanLink from '../ScanLink/ScanLink';
import AlgoliaConfig from '../Shared/algolia-config.service';
import withURLSync from '../Shared/URLSync';
import SupplierHttpService from '../Supplier/services/http.service';
import './Search.scss';
import { DangerousHighlight } from '../DangerousHighlight/DangerousHighlight';

const App = props => (
  <InstantSearch
    searchClient={SupplierHttpService.searchClient}
    indexName={AlgoliaConfig.getIndexName()}
    searchState={props.searchState}
    createURL={props.createURL}
    onSearchStateChange={props.onSearchStateChange}
  >
    <div className="grid-container">
      <div>
        <div className="hits-per-page-container">
          <HitsPerPage
            defaultRefinement={12}
            items={[
              { value: 12, label: 'Show 12 results' },
              { value: 36, label: 'Show 36 results' },
            ]}
          />
        </div>
      </div>

      <ScrollTo>
        <SearchBox autoFocus={true} showLoadingIndicator={true} />
      </ScrollTo>
    </div>

    <div className="grid-container">
      <div>
        <Facets />
      </div>

      <div className="search-panel">
        <div className="search-panel__results">
          <CustomResults />
        </div>
      </div>
    </div>
  </InstantSearch>
);

App.propTypes = {
  searchState: PropTypes.object.isRequired,
  createURL: PropTypes.func.isRequired,
  onSearchStateChange: PropTypes.func.isRequired,
};

const Hit = ({ hit }) => (
  <article className="hit">
    <div>
      <Link
        to={{
          pathname: `/contract/${hit.objectID}`,
        }}
      >
        <h3>
          <Highlight hit={hit} attribute="supplierName" />
        </h3>
      </Link>

      <h4 className="text-center">
        <Highlight hit={hit} attribute="contractNumber" />
      </h4>

      <h5>Commodity</h5>
      <p>
        <Highlight hit={hit} attribute="contractDescription" />
      </p>

      <h5>Contract Summary</h5>
      <p>
        <DangerousHighlight hit={hit} attribute="contractSummary" />
      </p>

      <h5>Contract Document</h5>

      <p>
        <ScanLink hit={hit} />
      </p>

      <h5>Vendor Contact</h5>
      <p>{hit.supplierSalesContactName}</p>
      <p>
        <a href={`tel:${hit.supplierSalesContactPhone}`}>
          {hit.supplierSalesContactPhone}
        </a>
        <br />
        <a href={`mailto:${hit.supplierSalesContactEmail}`}>
          {hit.supplierSalesContactEmail}
        </a>
      </p>
    </div>

    <div className="margin-top-15">
      <Link
        to={{
          pathname: `/contract/${hit.objectID}`,
        }}
      >
        Additional details&hellip;
      </Link>
    </div>
  </article>
);

Hit.propTypes = {
  hit: PropTypes.object.isRequired,
};

const CustomResults = connectStateResults(({ searchState, searchResults }) => (
  <div className="results-wrapper">
    {searchResults && searchResults.nbHits ? (
      <div>
        <Hits hitComponent={Hit} />
        <footer>
          <Pagination showLast={true} />
        </footer>
      </div>
    ) : (
      <div className="no-results">
        No results found matching &quot;
        <span className="query">{searchState.query}</span>
        &quot;
      </div>
    )}
  </div>
));

export default withURLSync(App);
