import React from 'react';
import PropTypes from 'prop-types';

function ScanLink(props) {
  if (props && props.hit && props.hit.scanExists) {
    return (
      <a
        target="blank"
        rel="noopener noreferrer"
        href={
          'https://procurement.vt.edu/' +
          `contracts/documents/${props.hit.contractNumber}.pdf`
        }
      >
        {`${props.hit.contractNumber}.pdf`}
        <i className="fas fa-external-link-alt margin-left-5" />
      </a>
    );
  }

  return (
    <span>
      Document unavailable, contact{' '}
      <a href="mailto:procurement@vt.edu">procurement@vt.edu</a>
    </span>
  );
}

ScanLink.propTypes = {
  hit: PropTypes.object.isRequired,
};

export default ScanLink;
