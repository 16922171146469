// Public interface.
const algoliaService = {
  // Properties.
  applicationId: 'WFSG9GGWNG',
  environmentProductionSentinel: 'production',

  // Methods.
  getApiKey,
  getIndexName,
};

// Private properties.
const apiDevelopment = {
  index: 'contract_dev',
  key: '58f8272e3e373e8284046218237db6a7',
};
const apiProduction = {
  index: 'contract_prod',
  key: '39fdf6a36fa364d0e7d0e20d538ba78a',
};

// Private methods.

/**
 * Dynamically return the API key based on whether the
 * current Node process was started in production mode.
 *
 * @returns {string} API key.
 */
function getApiKey() {
  if (process.env.REACT_APP_ALGOLIA_ENVIRONMENT !==
    algoliaService.environmentProductionSentinel) {
    return apiDevelopment.key;
  }

  return apiProduction.key;
}

/**
 * Dynamically return the Algolia index based on whether
 * the current Node process was started in production mode.
 *
 * @returns {string} Index name.
 */
function getIndexName() {
  if (process.env.REACT_APP_ALGOLIA_ENVIRONMENT !==
    algoliaService.environmentProductionSentinel) {
    return apiDevelopment.index;
  }

  return apiProduction.index;
}

export default algoliaService;