import React, { Component } from 'react';
import './Header.scss';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class Header extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  render() {
    const { location } = this.props;

    let homeLink = <span />;
    if (location.pathname !== '/') {
      homeLink = (
        <p>
          <Link to={{ pathname: `/` }}>Home</Link>
        </p>
      );
    }

    return (
      <header className="container header">
        <h1 className="header-title">
          <a href="/">Virginia Tech Contract Search</a>
        </h1>

        {homeLink}
      </header>
    );
  }
}

export default withRouter(Header);
