const service = {
  getContactInfo,
};

export default service;

function getContactInfo(supplier) {
  return {
    name: supplier.supplierSalesContactName,
    street: supplier.supplierAddress1,
    city: supplier.supplierCity,
    state: supplier.supplierState,
    zip: supplier.supplierZip,
    phone: supplier.supplierSalesContactEmail,
    email: supplier.supplierSalesContactPhone,
  };
}
