import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './ContactInformation.scss';

export default class ContactInformation extends Component {
  constructor(props) {
    super(props);

    if (props.contactInfo.name === undefined) {
      this.info = null;
    } else {
      this.info = props.contactInfo;
    }
  }

  render() {
    if (this.info) {
      return (
        <div className="contact-information">
          <address>
            {this.info.street}
            <br />
            {this.info.city},&nbsp;{this.info.state}&nbsp;{this.info.zip}
          </address>
        </div>
      );
    }

    return <h5 className="text-muted">Contact Information Not Available</h5>;
  }
}

ContactInformation.propTypes = {
  contactInfo: PropTypes.object.isRequired,
};
