import algoliasearch from 'algoliasearch/lite';
import AlgoliaConfig from '../../Shared/algolia-config.service';

const service = {
  getSupplierById,
  searchClient: algoliasearch(
    AlgoliaConfig.applicationId,
    AlgoliaConfig.getApiKey()
  ),
};

export default service;

const dataContext = service.searchClient.initIndex(
  AlgoliaConfig.getIndexName()
);

function getSupplierById(id) {
  return new Promise((resolve, reject) => {
    dataContext.getObject(id, (err, content) => {
      if (err) {
        reject(err.message);
      }

      resolve(content);
    });
  });
}
