import React from 'react';
import PropTypes from 'prop-types';
import { connectHighlight } from 'react-instantsearch-dom';

function Highlight({ highlight, attribute, hit }) {
  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit,
  });

  return (
    <span>
      {parsedHit.map(
        (part, index) =>
          part.isHighlighted ? (
            <em
              className="ais-Highlight-highlighted"
              key={index}
              dangerouslySetInnerHTML={{ __html: part.value }}
            />
          ) : (
            <span
              className="ais-Highlight-nonHighlighted"
              key={index}
              dangerouslySetInnerHTML={{ __html: part.value }}
            />
          )
      )}
    </span>
  );
}

Highlight.propTypes = {
  highlight: PropTypes.func.isRequired,
  attribute: PropTypes.string.isRequired,
  hit: PropTypes.object.isRequired,
};

export const DangerousHighlight = connectHighlight(Highlight);
